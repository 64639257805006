import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './boost-your-coding-fu-with-vscode-and-vim.module.css'
import { Link } from 'gatsby'
import Layout from '../vscodevimbook/Layout'
import { BoostCodingFuPodcastSEO } from '../components/seo'
import Button from '../components/Button'
import { HeroText } from '../vscodevimbook/components/hero-text/hero-text'

export default class BoostCodingFuVSCodeVimExercises extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const exercises = data.allMarkdownRemark.edges.map((e) => ({
      title: e.node.frontmatter.title,
      url: e.node.frontmatter.url,
      description: e.node.frontmatter.description,
      chapter: e.node.frontmatter.chapter,
      slug: e.node.fields.slug,
    }))
    return (
      <Layout>
        <article className={styles.page}>
          <BoostCodingFuPodcastSEO
            title={`Boost Your Coding Fu With VSCode and Vim - The Book | ${siteTitle}`}
            description={`Unleash the power of Vim in Visual Studio Code, boost your productivity and become an even more awesome developer. This is the vocal experience and experiment, companion to the Boost Your Coding Fu Book.`}
          />
          <header className={styles.header}>
            <section className={styles.headerbody}>
              <HeroText />
              <article className={styles.podcastContent}>
                <img
                  width="512"
                  height="512"
                  style={{ maxWidth: '300px' }}
                  src="/images/learn-vim-icon.png"
                  alt="Learn Vim extension icon"
                  title="Learn Vim right within VSCode"
                />
                <section>
                  <p>
                    <Link
                      to={
                        'https://marketplace.visualstudio.com/items?itemName=vintharas.learn-vim'
                      }
                    >
                      Learn Vim
                    </Link>{' '}
                    is a Visual Studio Code extension that helps you learn Vim
                    right within VSCode.
                  </p>
                  <p>
                    The best way to learn Vim in VSCode is to install the
                    extension and follow along reading Boost Your Coding Fu
                    while practicing with the exercises.
                  </p>
                  <p>
                    If you still want to learn Vim but you use another editor
                    (IntelliJ with IdeaVim, Sublime with vintage, Visual Studio
                    with VsVim, Vim/Neovim, etc) you can still take advantage of
                    these exercises below.
                  </p>
                  <footer className={styles.bookactions}>
                    <Button
                      pixelated
                      darkgreen
                      to={
                        'https://marketplace.visualstudio.com/items?itemName=vintharas.learn-vim'
                      }
                    >
                      Learn Vim
                    </Button>
                  </footer>
                </section>
              </article>
            </section>
          </header>
          <section className={styles.content}>
            <section className={styles.plugin}>
              <h2 style={{ margin: 0 }}>Exercises</h2>
            </section>
          </section>
          <section className={styles.content}>
            <Exercises exercises={exercises} />
          </section>
        </article>
      </Layout>
    )
  }
}

const Exercises = ({ exercises }) => (
  <ul className={styles.exercises}>
    {exercises.map((exercise) => (
      <li className={styles.exercise} key={exercise.chapter}>
        <section className={styles.exerciseDescription}>
          <h2>
            <span className={styles.exerciseNumber}>{exercise.chapter}.</span>{' '}
            <Link to={exercise.slug}>{exercise.title}</Link>
          </h2>
          <p>{exercise.description}</p>
        </section>
      </li>
    ))}
    <li className={styles.exercise} key={100}>
      <section className={styles.exerciseDescription}>
        <h2>
          <span className={styles.moreComingSoon}>More Coming Soon</span>
        </h2>
      </section>
    </li>
  </ul>
)

export const pageQuery = graphql`
  query BoostCodingFuVSCodeVimExercisesQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___chapter], order: ASC }
      filter: {
        frontmatter: {
          draft: { eq: false }
          exercises: { eq: "Boost Your Coding Fu With VSCode and Vim" }
        }
      }
    ) {
      edges {
        node {
          excerpt
          html
          fields {
            slug
          }
          frontmatter {
            date
            title
            categories
            description
            chapter
            url
          }
          timeToRead
        }
      }
    }
  }
`
